import request from '../../libs/axios'

export function getRegions(params){
  return request({
    url: '/api/region',
    method: 'get',
    params
  })
}

export function createRegionsById(){
  return request({
    url:'/api/region',
    method:'get'
  })
}

export function getRegionsById(data){
  return request({
    url: `/api/region/${data.id}`,
    method: 'get',
  })
}


export function addRegions(data){
  return request({
    url: '/api/region',
    method: 'post',
    data,
  })
}

export function editRegions(data){
  return request({
    url: `/api/region/${data.id}`,
    method: 'put',
    data,
  })
}

export function deleteRegions(data){
  return request({
    url: `/api/region/${data.id}`,
    method: 'delete'
  })
}
