<template>
  <div>
    <b-overlay :show="loading">

      <b-card>
        <b-table :fields="fields" :items="regions" responsive :busy="busy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(id)="data">
            <span class="text-md text-primary"> {{ (data.index + 1) }}</span>
          </template>
        </b-table>
      </b-card>

      <b-pagination v-model="pagination.page" :total-rows="total" :per-page="pagination.pageSize"
        @input="fetchRegions"></b-pagination>
    </b-overlay>

  </div>
</template>

<script>
  import {
    getRegions
  } from '@/api/regions/regions'
  export default {
    name: 'Index',
    data() {
      return {
        regions: [],
        perPage: 10,
        currentPage: 1,
        fields: [{
            key: 'id',
            label: 'ID',
          },
          {
            key: 'name_uz',
            label: this.$t('message.name_title')
          },
        ],
        formModalActive: false,
        pagination: {
          page: 1,
          pageSize: 20,
        },
        total: 1,
        busy: true,
        loading: false,
      }
    },
    created() {
      this.fetchRegions()
    },
    computed: {
      rows() {
        return this.regions.length
      }
    },
    methods: {
      fetchRegions() {
        this.loading = true;
        getRegions(this.pagination).then(response => {
          this.regions = response.data.data.data;
          this.total = response.data.data.total;
          this.busy = false;
          this.loading = false;
        })
      }
    }
  }
</script>

<style scoped>

</style>